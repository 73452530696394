import { useState } from "react";

import FloatingHelp from "../floating-help/floating-help.component";

import cross from "../../assets/cross.svg";

import "./help.styles.scss";
import ButtonPrimary from "../button-primary/button-primary.component";

const Help = ({ ...props }) => {
  const [showHelp, setShowHelp] = useState(false);

  return (
    <div className="help-container">
      {/* Floating Support Button */}
      {!showHelp && (
        <div className="btn-support" onClick={() => setShowHelp(true)}>
          <ButtonPrimary mid>Report an Issue</ButtonPrimary>
        </div>
      )}

      {/* FloatingHelp Component */}
      {showHelp && (
        <FloatingHelp showHelp={showHelp} setShowHelp={setShowHelp}>
          <img
            className="cross-img"
            src={cross}
            alt="Close"
            onClick={() => setShowHelp(false)}
          />
        </FloatingHelp>
      )}
    </div>
  );
};

export default Help;
