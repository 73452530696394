import { useState, useRef } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { descriptFormat, mailFormat } from "../../utils/regex";
import domtoimage from "dom-to-image";
import firebase from "../../firebase/firebase.utils";
import Upload from "../../assets/upload-icon.svg";
import ButtonPrimary from "../button-primary/button-primary.component";
import InputField from "../input-field/input-field.component";
import Delete from "../../assets/upload-delete.svg";
import File from "../../assets/file-icon.svg";
import "./floating-help.styles.scss";
import { formatFileName } from "../../utils/fileTypesUtil";
import {
  convertFileToBase64,
  getFileExtensionFromFile,
} from "../../utils/fileUtilities";

let initialState = {
  email: "",
  description: "",
};

const FloatingHelp = ({ showHelp, setShowHelp, ...props }) => {
  const [error, setError] = useState({
    email: false,
    description: "",
    file: false,
  });
  const [loading, setLoading] = useState(false);
  const [issueData, setIssueData] = useState(initialState);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const currentState = useSelector((state) => state);
  const user = useSelector((state) => state.base.user);

  useEffect(() => {
    if (user) {
      setIssueData({
        email: user.email,
        description: "",
        name: user.displayName,
      });
    }
  }, [user]);

  const handleChange = (value, name) => {
    let tempError = { ...error };
    if (value !== "") {
      if (name === "email") {
        if (value.match(mailFormat)) {
          tempError = { ...error, [name]: false };
        } else {
          tempError = { ...error, [name]: true };
        }
      }
      if (name === "description") {
        if (value.match(descriptFormat)) {
          tempError = { ...error, [name]: false };
        } else {
          tempError = { ...error, [name]: true };
        }
      }
    } else {
      tempError = { ...error, [name]: true };
    }

    setError({ ...tempError });
    setIssueData({ ...issueData, [name]: value });
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size <= 5 * 1024 * 1024) {
      setFile(selectedFile);
      setError({ ...error, file: false });
    } else {
      setFile(null);
      setError({ ...error, file: "File size exceeds 5 MB" });
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const image = await domtoimage.toPng(document.getElementById("root"));

      let fileBase64 = null;
      let extension = null;

      if (file) {
        fileBase64 = await convertFileToBase64(file);
        extension = getFileExtensionFromFile(file);
      }

      const reportIssue = firebase.functions().httpsCallable("reportIssue");
      const resp = await reportIssue({
        ...issueData,
        image: image,
        state: JSON.stringify(currentState),
        Uploadfile: fileBase64,
        extension,
      });

      if (resp) {
        setError({ email: true, description: true });
        setIssueData({ email: user.email, description: "" });
        setFile(null);
        setShowHelp(false);
      }
    } catch (err) {
      console.error("Error in handleSubmit:", err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="button-floating-container">
      <button className="button-floating" {...props}>
        {props.children}
      </button>
      {showHelp ? (
        <div className="help-modal">
          <div className="help-modal-header">Report an issue</div>
          <div className="help-modal-body">
            <div className="email-section">
              <span>From :</span>
              <InputField
                error={error.email}
                success={!error.email && issueData.email !== ""}
                value={issueData.email}
                onChange={(e) => handleChange(e.target.value, "email")}
                placeholder="Your email address"
                disabled={loading}
              />
            </div>

            <InputField
              error={error.description}
              success={!error.description && issueData.description !== ""}
              value={issueData.description}
              onChange={(e) => handleChange(e.target.value, "description")}
              textarea
              disabled={loading}
              placeholder="Describe your issue"
            />

            <div className="upload-section">
              {!file ? (
                <button className="upload-file-btn" onClick={handleFileClick}>
                  <div className="upload-btn-inner">
                    <img src={Upload} alt="Upload icon" />
                    <span>Upload file</span>
                  </div>
                </button>
              ) : (
                <div className="file-details">
                  <div className="file-name-section">
                    <img src={File} alt="file icon" />
                    <span className="file-name">
                      {formatFileName(file.name)}
                    </span>
                  </div>
                  <img
                    src={Delete}
                    alt="Delete icon"
                    onClick={handleDeleteFile}
                  />
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />

              <span className="size-text">Maximum upload size: 5 MB</span>
              {error.file && <span className="error-text">{error.file}</span>}
            </div>

            <ButtonPrimary
              disabled={
                error.email ||
                error.description ||
                loading ||
                issueData.email === "" ||
                issueData.description === "" ||
                error.file
              }
              center
              mid
              loading={loading}
              onClick={handleSubmit}
            >
              Send
            </ButtonPrimary>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FloatingHelp;
