// utils/fileUtils.js

export const formatFileName = (fileName) => {
  const extension = fileName.slice(fileName.lastIndexOf("."));
  const nameWithoutExtension = fileName.slice(0, fileName.lastIndexOf("."));
  const nameParts = nameWithoutExtension.split(" ");

  // Limit to first two words and add "..." if there are more
  const displayedName =
    nameParts.length > 2
      ? `${nameParts.slice(0, 2).join(" ")}...${extension}`
      : `${nameWithoutExtension}${extension}`;

  return displayedName;
};
